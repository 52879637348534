/* eslint brace-style: ["error", "stroustrup"] */
import {
  useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import { IconButton } from '@mui/material';
import * as L from 'leaflet';
import PentagonTwoToneIcon from '@mui/icons-material/PentagonTwoTone';
import { useTranslation } from 'react-i18next';
import * as loglevel from 'loglevel';
import { useSelection } from '../../../contexts/selection/selection-context';
import { themes } from '../../mui/themes';
import SEPContext from '../../../contexts/sep-context/SEPContext';
import { useMap } from '../../../contexts/map/map-context';
import { useDashboard } from '../../../contexts/dashboard/DashboardProvider';
import ControlButton from './ControlButton';
import env from '../../../env/env';
import {
  latLngsToWkt, parseWKT, svgIcon, validatePolygon,
} from './utils';
import { SELECTION_MODES } from '../../../contexts/selection/constants';
import useToast from '../../../hooks/useToast';
import { DASHBOARD } from '../../../contexts/dashboard/constants';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

function PolygonMode() {
  const { user: { jwtParsed, jwt } } = useContext(SEPContext).SEPContext;
  const role = jwtParsed
    ? jwtParsed['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
    : undefined;
  const {
    isPolygonMode,
    setSelectionMode,
    polygons,
    setPolygons,
    drawnItems,
    polygonDrawingsRef,
    clearPolygons,
    isPolygonEditMode,
    setIsPolygonEditMode,
  } = useSelection();
  const { t } = useTranslation('controls_map');
  const { mapRef, isMapReady } = useMap();
  const { setCoverage } = useDashboard();
  const toast = useToast();

  const [hasVertices, setHasVertices] = useState(false);
  const drawHandler = useRef(null);
  const editingPolygon = useRef(null);

  // this needs to be a method to end the event listener to avoid a memory leak
  const drawCreatedHandler = useCallback(async (event) => {
    const { layer } = event;
    const latlngs = layer.getLatLngs()[0];
    const wktString = latLngsToWkt(latlngs);
    const newPolygons = [wktString];
    setPolygons(newPolygons);
    const polygonValidity = await validatePolygon(wktString, jwt);
    if (polygonValidity !== 'valid') {
      toast.warning(t(`polygon-selection-error-${polygonValidity}`), { displayMode: 'replace' });
      clearPolygons();
    }
    else {
      polygonDrawingsRef.current.push(layer);
      const polygonSelectionEvent = new CustomEvent('kibana-event', {
        detail: {
          type: 'building-selection',
          eventInfo: {
            info: 'Polygon has been selected.',
          },
        },
      });
      window.dispatchEvent(polygonSelectionEvent);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // this called on component unmount and when user exits the polygon selection mode
  // this makes sure that the event listeners also are disabled to prevent memory leak
  const disableDrawing = useCallback(() => {
    if (drawHandler.current) {
      drawHandler.current.disable();
    }
    if (editingPolygon.current && editingPolygon.current.editing) {
      editingPolygon.current.editing.disable();
      setIsPolygonEditMode(false);
      editingPolygon.current = null;
    }
    if (mapRef.current) {
      mapRef.current.off(L.Draw.Event.CREATED, drawCreatedHandler);
    }
  }, [drawCreatedHandler, mapRef, setIsPolygonEditMode]);

  const handleSelectionToggle = useCallback(() => {
    let newMode = '';
    setSelectionMode((prev) => {
      const n = prev === SELECTION_MODES.POLYGON
        ? SELECTION_MODES.COMPLETE_BUILDING
        : SELECTION_MODES.POLYGON;
      newMode = n;
      return n;
    });

    if (newMode === SELECTION_MODES.COMPLETE_BUILDING) {
      disableDrawing();
      setCoverage(DASHBOARD.MID);
    }

    toast.success(t(`${newMode}-selection-mode`));
  }, [setSelectionMode, t, toast, setCoverage, disableDrawing]);

  const enableDrawing = useCallback(() => {
    if (!isMapReady || !isPolygonMode || polygons.length) return;

    if (!drawHandler.current) {
      drawHandler.current = new L.Draw.Polygon(mapRef.current, {
        allowIntersection: false, // Restricts shapes to simple polygons
        shapeOptions: {
          color: themes.geoimpact.palette.error.main,
          weight: 5,
          opacity: 0.7,
        },
        guidelineDistance: 25,
        icon: svgIcon,
      });
    }

    drawHandler.current.enable();
    mapRef.current.on(L.Draw.Event.CREATED, drawCreatedHandler);
  }, [isMapReady, isPolygonMode, polygons, mapRef, drawCreatedHandler]);

  // this effect handles the case when user clicks Escape button
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27 && isPolygonMode && !polygons.length) {
        if (hasVertices) {
          enableDrawing();
          setHasVertices(false);
        }
        else {
          disableDrawing();
          setCoverage(DASHBOARD.MID);
          setSelectionMode(SELECTION_MODES.COMPLETE_BUILDING);
          toast.success(t(`${SELECTION_MODES.COMPLETE_BUILDING}-selection-mode`));
        }
      }
    };

    window.addEventListener('keyup', handleKeyDown);
    return () => {
      window.removeEventListener('keyup', handleKeyDown);
    };
  }, [
    disableDrawing,
    enableDrawing,
    setSelectionMode,
    hasVertices,
    isPolygonMode,
    setCoverage,
    polygons,
    t, toast,
  ]);

  const vertexAddedHandler = useCallback(() => {
    // Vertex has been added
    setHasVertices(true);
  }, []);

  useEffect(() => {
    if (!isMapReady) return undefined;
    const map = mapRef.current;

    const handleClickOutside = async () => {
      if (editingPolygon.current && isPolygonEditMode) {
        const latLngs = editingPolygon.current.getLatLngs()[0];
        const wktString = latLngsToWkt(latLngs);
        const polygonValidity = await validatePolygon(wktString, jwt);
        if (polygonValidity !== 'valid') {
          toast.warning(t(`polygon-selection-error-${polygonValidity}`), { displayMode: 'replace' });
        }
        else {
          setPolygons([wktString]);
          editingPolygon.current.editing.disable();
          setIsPolygonEditMode(false);
          const polygonSelectionEvent = new CustomEvent('kibana-event', {
            detail: {
              type: 'building-selection',
              eventInfo: {
                info: 'Polygon has been edited.',
              },
            },
          });
          window.dispatchEvent(polygonSelectionEvent);
        }
      }
    };

    map.on('click', handleClickOutside);

    return () => {
      map.off('click', handleClickOutside);
    };
  }, [
    isMapReady,
    mapRef,
    isPolygonEditMode,
    jwt, t,
    setPolygons,
    toast,
    setIsPolygonEditMode,
  ]);

  // this effect handles the case when user clicks Escape button
  useEffect(() => {
    if (!isMapReady) return undefined;
    const map = mapRef.current;

    map.on(L.Draw.Event.DRAWVERTEX, vertexAddedHandler);

    return () => {
      map.off(L.Draw.Event.DRAWVERTEX, vertexAddedHandler);
    };
  }, [isMapReady, mapRef, vertexAddedHandler]);

  // this effect handles the case when polygon selection mode is entered
  useEffect(() => {
    if (!isMapReady) return;

    if (isPolygonMode && !polygons.length) {
      enableDrawing();
      setCoverage(DASHBOARD.LOW);
    }
  }, [isMapReady, enableDrawing, isPolygonMode, polygons, setCoverage]);

  // this effect sets the dashboard coverage to the mid-position when polygon is drawn
  useEffect(() => {
    if (isPolygonMode && polygons.length) {
      setCoverage(DASHBOARD.MID);
    }
  }, [isPolygonMode, polygons, setCoverage]);

  // this effect overwrites the tooltip text of polygon creation
  useEffect(() => {
    const errorText = t('draw-polygon-error');
    const startText = t('draw-polygon-start');
    const contText = t('draw-polygon-cont');
    const endText = t('draw-polygon-end');

    L.drawLocal.draw.handlers.polyline.error = errorText;
    L.drawLocal.draw.handlers.polygon.tooltip.start = startText;
    L.drawLocal.draw.handlers.polygon.tooltip.cont = contText;
    L.drawLocal.draw.handlers.polygon.tooltip.end = endText;
  }, [t]);

  // this effect handles the display of the polygons on the map
  useEffect(() => {
    if (!isMapReady) return;

    // clear any existing polygons first
    if (drawnItems.current) {
      drawnItems.current.clearLayers();
    }

    drawnItems.current = L.featureGroup().addTo(mapRef.current);
    polygons.forEach((wktString) => {
      const latLngs = parseWKT(wktString);
      const polygon = L.polygon(latLngs, {
        color: themes.geoimpact.palette.error.main,
        weight: 5,
        opacity: 0.7,
      }).addTo(drawnItems.current);
      polygonDrawingsRef.current.push(polygon);

      // bind the click event to start editing
      polygon.on('click', () => {
        editingPolygon.current = polygon;
        polygon.editing.enable();
        setIsPolygonEditMode(true);
      });
    });
  }, [
    isMapReady,
    polygons,
    mapRef,
    drawnItems,
    polygonDrawingsRef,
    setIsPolygonEditMode,
  ]);

  useEffect(() => {
    // Restore polygons state from ref if it's empty
    if (polygons.length === 0 && polygonDrawingsRef.current.length > 0) {
      const restoredPolygons = polygonDrawingsRef.current
        .map((layer) => latLngsToWkt(layer.getLatLngs()[0]));
      setPolygons(restoredPolygons);
    }
  }, [polygons, setPolygons, polygonDrawingsRef]);

  return role !== 'Guest' && (
    <ControlButton
      isActive={isPolygonMode}
      p={0}
      onClick={() => handleSelectionToggle()}
      className="control polygon"
    >
      <IconButton sx={{ color: 'white', marginTop: '-1px' }}>
        <PentagonTwoToneIcon />
      </IconButton>
    </ControlButton>
  );
}

export default PolygonMode;
